.form-field-select {
  div[class$="-menu"] {
    z-index: 100 !important;
  }

  div[class$="-option"] {
    &:hover {
      background-color: #F6F5F4;
    }
  }
}
