.react-datepicker {
  &--time-only {
    border: 1px solid #e0e0e0 !important;
  }

  &--time-only {
    .react-datepicker {
      &__time-container {
        width: 100% !important;
      }
    } 
  }

  &__header {
    &--time--only {
      background-color:#e0e0e0 !important;
    }
  }
}