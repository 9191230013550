@import '../../variables';

.header-topnav {

  > ul {
    @extend .navbar-nav;
    @extend .d-flex;
    @extend .flex-row;

    > li {
      @extend .nav-item;
      @extend .d-flex;
      @extend .flex-column;

      > a {
        @extend .nav-link;
        @extend .d-flex;
        @extend .flex-column;
        flex-grow: 1;
        //

        text-align: center;
        color: $heathmont-color-primary;
        font-size: 1rem;

        > * {
          @extend .d-flex;
          @extend .align-self-center;
        }

        > svg, path {
          @extend .d-flex;
          margin-right: 0.25rem;
          font-size: 1.5rem;
          fill: darken($heathmont-color-primary, 20)
        }

        &.active {
          //@extend .rounded;
          //border-radius: .5rem;
          padding-bottom: .25rem;
          @include linear-gradient(null, darken($heathmont-color-primary, 20%), $heathmont-color-primary);
          color: $heathmont-color-bg;

          > svg, path {
            fill: $heathmont-color-bg;
          }
        }

      }
    }
  }
}
