@import '../../variables';

.alertBlock {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .container;

  > div {
    @extend .alert;
    @extend .alert-danger;
    @extend .d-flex;
    @extend .flex-column;
    @extend .w-100;

    > pre {
      font-size: 0.75rem;
    }
  }
}
