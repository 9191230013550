.custom-select {
  &--right {
    &__menu {
      right: 1px;
    }
  }

  &--left {
    &__menu {
      left: 1px;
    }
  }

  &--right, &--left {
    &__menu {
      &-portal {        
        z-index: 9999 !important;
        font-size: 12px !important;
      }
    }
  }

  &--left, &--right {
    &__single-value {
      display: flex;
      align-items: center;

      svg {
        display: none;
      } 
    }

    &__option {
      &:hover {
        background-color: #F6F5F4;
      }
    }

    &__option--is-selected {
      background-color: #F6F5F4 !important;

      svg {
        display: block;
      }
    }

    &__value-container {
      padding-right: 0 !important;
      padding-left: 4px !important;

      &--has-value {
        > div {
          max-width: 90% !important;
          display: flex;
    
          > span {
            position: relative;
            padding-right: 20px;
            display: block;
            line-height: 0.625rem;
            background: rgba(253,239,196,0.38) !important;
            color: #181B1C !important;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            > span {
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          svg {
            color: #181B1C !important;
          }
        }
      }
    }

    &__indicators {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }

    &__clear-indicator {
      padding: 0 !important;
    }
  }  
}