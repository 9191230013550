@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import './colors';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

//  Bootstrap
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-grid-classes: true;
$enable-print-styles: true;

// Typography
$font-size-base: 20px; // this changes the font-size throughout bootstrap
$font-family-sans-serif: 'Helvetica', sans-serif;
$font-size-base: 1.5rem;
$line-height-base: 1.6;

/*************
  HEATHMONT TRAVEL
*************/

//  HEADER
$heathmont-header-size: 4rem;

//  FOOTER
$heathmont-footer-size: 10rem;

@import '~bootstrap/scss/variables';

// components to import from BS
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/jumbotron';

@import 'bootstrap';
