@import './variables';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

html {
  @extend .h-100;
  width: 100%;
  height: 100%;
  overflow-y: auto !important;

  body {
    // This resolving problem with zooming focus on ios devices
    input, select:focus, textarea {
      font-size: 16px !important;
    }
    // @extend .d-flex;
    // @extend .flex-column;
    // @extend .h-100;
    // @extend .w-100;
    background-color: $heathmont-color-bg;
    color: $heathmont-color-text;
    width: 100%;
    height: 100%;

    a {
      color: $heathmont-color-text;

      &:hover {
        color: $heathmont-color-text-muted;
        text-decoration: none;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    border-style: none;
  }
}

@import 'bootstrap';
@import 'react-datepicker/dist/react-datepicker.css';

[data-reach-listbox-list] {
  max-height: 190px;
  overflow-y: auto;
  margin-bottom: 0;
}

[data-reach-listbox-list] div,
[data-reach-listbox-input] {
  font-size: 14px !important;
}

/* react-datepicker cutom styles */
.react-datepicker {
  border: none;
}
.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  z-index: 100;
  border: none;
}
.react-datepicker__year-select,
.react-datepicker__month-select,
.react-datepicker-popper * {
  border: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__navigation-icon::before {
  border-color: #181b1c;
  border-width: 1px 1px 0 0;
  height: 10px;
  top: 10px;
  width: 10px;
}

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 0.75rem;
  border: none;
  box-shadow: 0 0 0 0.0625rem #E0E0E0 inset;
  font-size: 14px;
  line-height: 2.5rem;
  background-image: url('./static/icons/calendar.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding: 4px 16px;
  border-width: 0.125rem;

  &:hover {
    box-shadow: inset 0 0 0 0.125rem #181B1C12, inset 0 0 0 0.125rem #E0E0E0;
    cursor: pointer;
    border-radius: 0.75rem;
  }
}

.react-datepicker__month-container {
  background-color: #fff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  overflow: hidden;
}

.react-datepicker__current-month {
  font-size: 12px;
  font-weight: 400;
  z-index: 1000;
  display: none;
}
.red-border {
  border-color: #ff1f39 !important;
}

.box:has(> .popover-dialog) {
  padding: 0 !important;
}

br {
  height: 1.5rem !important;
  display: block !important;
  content: '' !important;
}

.Toastify__toast {
  font-size: 1rem !important;
}

div [class^='AccordionContent'] {
  margin: -10px !important;
  padding: 10px !important;
}
