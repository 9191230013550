@import '../../variables';

.header-userinfo {
  @extend .d-flex;
  @extend .ml-auto;

  > a {
    @extend .d-flex;
    @extend .flex-column;
    font-size: 1rem;

    > * {
      @extend .d-flex;
      @extend .align-self-center;
    }

    > svg {
      font-size: 1.5rem;
      margin-top: 0.35rem;
      margin-right: 0.25rem;
    }

    padding-left: 1rem;
  }
}
