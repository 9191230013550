@import '../../variables';

.text-page {
  @extend .h-100;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .container;
  @extend .align-items-center;
  color: $heathmont-color-text;

  > h2 {
    @extend .p-4;
    font-weight: 900;
  }

  > div {
    @extend .p-2;

    > a {
      @extend .btn;
      @extend .btn-success;

      > svg {
        margin-right: 1rem;
      }
    }
  }

  > * {
    text-align: center;
  }
}
