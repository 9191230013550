@import '../../variables';

header {
  @extend .shadow;
  @extend .d-flex;
  min-height: $heathmont-header-size;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: $heathmont-color-bg;

  > nav {
    @extend .navbar;
    @extend .navbar-expand-md;
    @extend .w-100;
    padding-top: 0;
    padding-bottom: 0;

    > a {
      @extend .navbar-brand;
      color: $heathmont-color-text;
      font-weight: 600;
      font-size: 1.75rem;
    }
  }
}
