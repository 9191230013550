@import '../../variables';

.app-spinner {
  @extend .w-100;
  @extend .h-100;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .flex-fill;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > svg {
    @extend .align-self-center;
  }
}
