@import '../../variables';

#root {
  @extend .d-flex;
  @extend .flex-column;
  @extend .h-100;

  > main {
    @extend .w-100;
    @extend .d-flex;
    @extend .flex-column;
    flex-grow: 1;
    flex-basis: auto;

    > section {
      @extend .d-flex;
      @extend .flex-column;
      @extend .flex-fill;

      .sidebar-container {
        @extend .d-flex;
        @extend .flex-column;
        @extend .w-100;

        > * {
          @extend .d-flex;
        }

        > h2 {
          padding-bottom: 1rem;
          margin: 0 1rem;

          > svg {
            margin-right: 0.75rem;
          }
        }

        &__wrapper {
          @extend .d-flex;
          @extend .flex-row;
          @extend .w-100;
          @extend .h-100;
          @extend .align-self-stretch;
          @extend .justify-content-start;

          > * {
            @extend .d-flex;
            @extend .flex-fill;
          }

          > :first-child {
            @extend .col-3;
          }

          > :nth-child(2) {
            @extend .col-9;
          }
        }

        &__header {
          @extend .d-flex;
          @extend .flex-row;
          @extend .w-100;
          @extend .justify-content-between;

          .action-btn {
            @extend .btn;
            @extend .btn-outline-primary;
          }
        }
      }
    }
  }
}
