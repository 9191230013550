@import '../../variables';

footer {
  @extend .mt-4;
  @extend .d-flex;
  @extend .mt-auto;
  @extend .justify-content-between;
  @extend .shadow;
  @extend .w-100;
  font-size: 1rem;
  color: $heathmont-color-text;

  .footer-contacts {
    @extend .w-100;
    @extend .d-flex;
    @extend .flex-row;
    color: $heathmont-color-text;
    @extend .m-4;

    //  the container
    > div {
      @extend .d-flex;
      @extend .flex-column;
      @extend .p-4;

      svg {
        @extend .mr-1;
      }

      //  elements
      > h5 {
        font-weight: 900;
      }

      > p {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      > a {
        text-decoration: underline;
      }

      > small {
        font-size: 1rem;
      }
    }
  }
}
